import GlobalLoading from "./components/GlobalLoading";
import { configDocumentLinks } from "./configs/document";
import { themeCookie } from "./server/sessions.server";
import { Toaster } from "./shadcn/components/ui/toaster";
import { LinksFunction, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "@remix-run/react";
import { useEffect } from "react";
import ThemeProvider, { Theme } from "~/components/ThemeProvider";
import { TooltipProvider } from "~/shadcn/components/ui/tooltip";

export type OutletContext = {
  state: "idle" | "loading" | "submitting";
};

// Links function to include the CSS
export const links: LinksFunction = () => {
  return configDocumentLinks;
};

// Meta function to include additional meta tags
export const meta: MetaFunction = () => {
  return [{ title: "Vendur - Home" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const themeOrUndefined = await themeCookie.parse(request.headers.get("Cookie"));
  const theme = themeOrUndefined || "light";
  return { theme };
}

export default function App() {
  const { theme } = (useLoaderData() as { theme: Theme }) || { theme: "light" };
  const { state } = useNavigation();
  const outletContext = { state };

  // Use useEffect to safely run client-side code
  useEffect(() => {
    // Detect client's timezone
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    document.cookie = `timezone=${clientTimezone}; path=/;`;
  }, []); // Empty dependency array ensures this runs only on initial mount

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-L2HQLR8LNY" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-L2HQLR8LNY');
            `,
          }}
        />
      </head>
      <body className="text-simpleText bg-simpleBackground">
        <ThemeProvider initialState={theme}>
          <TooltipProvider>
            <GlobalLoading>
              <Outlet context={outletContext} />
            </GlobalLoading>
            <Toaster />
          </TooltipProvider>
        </ThemeProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
